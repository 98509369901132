import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./createpsw.scss";
import logo from "../../assets/myLogo.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PasswordIcon from "@mui/icons-material/Password";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";

export default function CreatePassword() {
  const navigate = useNavigate();
  const [userSubsId, setUserSubsId] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const userRef = useRef();
  const passwordRef = useRef();
  const errRef = useRef();
  const btnRef = useRef();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disableSms, setDisableSms] = useState(true);
  const [className, setClassName] = useState("");

  const [focusedId, setFocusedId] = useState(false);
  const [errId, setErrId] = useState("");
  const [errPsw, setErrPsw] = useState("");
  const [focusedPsw, setFocusedPsw] = useState(false);
  const [myTimer, setMyTimer] = useState(120);
  const minutes = Math.floor(myTimer / 60);
  const seconds = myTimer - minutes * 60;

  useEffect(() => {
    const interv = setInterval(() => {
      btnRef.current && setMyTimer((prev) => (prev >= 1 ? prev - 1 : 0));
    }, 1000);

    if (myTimer === 0) {
      setDisableSms(false);
      setClassName("timeSpan");
    } else {
      setClassName("");
    }

    return () => clearInterval(interv);
  }, [myTimer]);

  const handleFocusedId = (e) => {
    setFocusedId(true);
    if (userSubsId === "" || userSubsId.length < 5)
      setErrId("Длина пароля: не менее 6 символов");
  };

  const handleFocusedPsw = (e) => {
    setFocusedPsw(true);
    if (userSubsId !== userPassword) setErrPsw("Пароли не совпадают");
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const newPsw = "https://api.cyxym.net/app/v1?account.password";
  const sendCode = "https://api.cyxym.net/app/v1?account.sendSMS";

  useEffect(() => {
    document.title = "Смена пароля";
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [userSubsId, userPassword]);

  const sendSms = async () => {
    const body = new FormData();

    try {
      body.append("id", localStorage.getItem("id"));
      body.append("token", localStorage.getItem("token"));

      // * fething data
      const response = await fetch(sendCode, {
        body: body,
        method: "POST",
      });

      const data = await response.json();

      if (data.response?.status) {
        setIsDisabled(false);
      } else {
        throw new Error(setErrMsg(data.response.message));
      }
    } catch (err) {}
  };

  const submitHandler = useCallback(async () => {
    const body = new FormData();

    try {
      if (
        userSubsId === userPassword &&
        userSubsId !== "" &&
        userPassword !== ""
      ) {
        var newPassword = userSubsId;
        body.append("id", localStorage.getItem("id"));
        body.append("password", newPassword);
        body.append("token", localStorage.getItem("token"));
        body.append("code", smsCode);

        // * fething data
        const response = await fetch(newPsw, {
          body: body,
          method: "POST",
        });

        const data = await response.json();
        if (data.response.status) {
          setUserSubsId("");
          setUserPassword("");
          localStorage.setItem("new_psw", true);
          navigate("/");
        } else {
          throw new Error(setErrMsg(data.response.message));
        }
      } else {
        setErrMsg("Введённые пароли не совпадают");
      }
    } catch (error) {
      errRef.current.focus();
    }
  }, [navigate, userPassword, userSubsId, smsCode]);

  useEffect(() => {
    window.onkeydown = (event) => {
      if (event.keyCode === 13 && (userRef.current || passwordRef.current)) {
        submitHandler();
      }
    };
  }, [submitHandler]);

  return (
    <div className="createPsw">
      <div className="loginContainer">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <div className="logo">
          <img src={logo} alt="systema-logo" />
        </div>

        <div className="cabinetText">Смена пароля</div>
        <div className="inputs">
          <div className="idIcon">
            <PasswordIcon style={{ color: "#29a9e0" }} />
            <input
              type={passwordShown ? "text" : "password"}
              placeholder="Новый пароль"
              value={userSubsId}
              ref={userRef}
              required
              onChange={(e) => setUserSubsId(e.target.value)}
              onBlur={handleFocusedId}
              focused={focusedId.toString()}
              pattern="^.{6,}$"
            />

            <span>{errId}</span>
            {passwordShown ? (
              <VisibilityOffIcon
                style={{ color: "#29a9e0", cursor: "pointer" }}
                onClick={togglePassword}
              />
            ) : (
              <VisibilityIcon
                style={{ color: "#29a9e0", cursor: "pointer" }}
                onClick={togglePassword}
              />
            )}
          </div>

          <div className="idIcon">
            <PasswordIcon style={{ color: "#29a9e0" }} />
            <input
              type={passwordShown2 ? "text" : "password"}
              value={userPassword}
              ref={passwordRef}
              placeholder="Подтвердить пароль"
              onChange={(e) => setUserPassword(e.target.value)}
              required
              onBlur={handleFocusedPsw}
              focused={focusedPsw.toString()}
              pattern={userSubsId}
            />
            <span>{errPsw}</span>
            {passwordShown2 ? (
              <VisibilityOffIcon
                style={{ color: "#29a9e0", cursor: "pointer" }}
                onClick={togglePassword2}
              />
            ) : (
              <VisibilityIcon
                style={{ color: "#29a9e0", cursor: "pointer" }}
                onClick={togglePassword2}
              />
            )}
          </div>

          <div
            className="idIcon"
            style={isDisabled ? { opacity: "0.5" } : { opacity: "1" }}
          >
            <EmailIcon style={{ color: "#29a9e0" }} />
            <input
              type="text"
              placeholder="код из смс"
              disabled={isDisabled}
              onChange={(e) => setSmsCode(e.target.value)}
              value={smsCode}
            />
          </div>
        </div>

        {isDisabled ? (
          <Button
            className="button"
            onClick={sendSms}
            disabled={
              userSubsId !== "" &&
              userPassword !== "" &&
              userSubsId === userPassword &&
              userSubsId.length > 5 &&
              userPassword.length > 5
                ? false
                : true
            }
            style={
              userSubsId !== "" &&
              userPassword !== "" &&
              userSubsId === userPassword &&
              userSubsId.length > 5 &&
              userPassword.length > 5
                ? { opacity: "1" }
                : { opacity: "0.5" }
            }
          >
            запросить смс код
          </Button>
        ) : (
          <Button
            className="button"
            onClick={submitHandler}
            disabled={smsCode !== "" && smsCode.length === 4 ? false : true}
            style={
              smsCode !== "" && smsCode.length === 4
                ? { opacity: "1" }
                : { opacity: "0.5" }
            }
          >
            сохранить
          </Button>
        )}

        {!isDisabled && (
          <Button
            className="button"
            style={disableSms ? { opacity: "0.5" } : { opacity: "1" }}
            disabled={disableSms}
            onClick={() => {
              sendSms();
              setMyTimer(120);
              setDisableSms(true);
            }}
            ref={btnRef}
          >
            запросить смс
            <span style={{ marginLeft: "5px" }} className={className}>
              {minutes.toString().padStart(2, "0")}
            </span>
            <span className={className}>:</span>
            <span className={className}>
              {seconds.toString().padStart(2, "0")}
            </span>
          </Button>
        )}
      </div>
    </div>
  );
}
