import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import "./confirmPage.scss";
import logo from "../../assets/myLogo.png";
import AppContext from "../../components/AppContext";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";

export default function ConfirmPage() {
  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();
  const [sms, setSms] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const confirmRef = useRef();
  const errRef = useRef();

  const verifyMsg = "https://api.cyxym.net/app/v1?auth.verify";

  useEffect(() => {
    confirmRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [sms]);

  const handleConfirm = useCallback(async () => {
    try {
      const body = new FormData();
      body.append("id", localStorage.getItem("id"));
      body.append("code", sms);
      const response = await fetch(verifyMsg, {
        body: body,
        method: "POST",
      });

      const data = await response.json();
      if (data.response.status) {
        setAuth({
          ...auth,
          status: data.response.status,
          token: data.response.token,
        });
        localStorage.setItem("token", data.response.token);
        localStorage.setItem("id", localStorage.getItem("id"));
        navigate("/create_password");
      } else {
        throw new Error(setErrMsg(data.response.message));
      }
    } catch (error) {
      errRef.current.focus();
    }
  }, [auth, setAuth, navigate, sms]);

  useEffect(() => {
    window.onkeydown = (event) => {
      if (event.keyCode === 13 && confirmRef.current) handleConfirm();
    };
  }, [handleConfirm]);

  return (
    <div className="confirmPage">
      <div className="confirmContainer">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <div className="logo">
          <img src={logo} alt="systema-logo" />
        </div>
        <div className="inputs">
          <div className="idIcon">
            <EmailIcon style={{ color: "#29a9e0" }} />
            <input
              type="text"
              placeholder="Код из SMS"
              required
              ref={confirmRef}
              onChange={(e) => setSms(e.target.value)}
            />
          </div>
        </div>
        <Button className="button" onClick={handleConfirm}>
          подтвердить
        </Button>
      </div>
    </div>
  );
}
