import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequiredAuth = () => {
  const location = useLocation();

  return localStorage.getItem("token") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequiredAuth;
