import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const HomePageContext = createContext();

export const HomeProvider = ({ children }) => {
  const id = localStorage.getItem("id") && localStorage.getItem("id");
  const token = localStorage.getItem("token") && localStorage.getItem("token");
  const userInfo = "https://api.cyxym.net/app/v1?account";
  const activePay = "https://api.cyxym.net/app/v1?pay.temp";
  const blockUrl = "https://api.cyxym.net/app/v1?account.lock";
  const connectionInfo = "https://api.cyxym.net/app/v1?account.link";
  const getMsg = "https://api.cyxym.net/app/v1?chat";

  const [info, setInfo] = useState([]);
  const [connInfo, setConnInfo] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [fullName, setFullName] = useState("");
  const [messages, setMessages] = useState([]);
  const [tempPay, setTempPay] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messLoading, setMessLoading] = useState(true);

  const navigate = useNavigate();

  const blockUser = async () => {
    setLoading(true);
    const body = new FormData();
    body.append("id", id);
    body.append("token", token);
    body.append("lock", isLocked ? 0 : 1);

    const response = await fetch(blockUrl, {
      method: "POST",
      body: body,
    });
    const data = await response.json();

    if (data.response.status || !data.response.status) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const activeTempPay = async () => {
    setLoading(true);
    const body = new FormData();
    body.append("id", id);
    body.append("token", token);
    const response = await fetch(activePay, {
      method: "POST",
      body: body,
    });
    const data = await response.json();
    if (data.response.status) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    const getConnectionInfo = async () => {
      const body = new FormData();
      body.append("id", id);
      body.append("token", token);

      const response = await fetch(connectionInfo, {
        method: "POST",
        body: body,
        signal: controller.signal,
      });

      const data = await response.json();
      setConnInfo(data?.response?.data);
    };

    const getData = async () => {
      setLoading(true);
      const body = new FormData();
      body.append("id", id);
      body.append("token", token);
      const response = await fetch(userInfo, {
        method: "POST",
        body: body,
        signal: controller.signal,
      });

      const data = await response.json();
      if (data.response.status) {
        setInfo(data?.response?.data);
        setFirstName(data?.response?.data?.firstname?.charAt(0));
        setMiddleName(data?.response?.data?.middlename?.charAt(0));
        setFullName(data?.response?.data?.fullname);
        setTempPay(data?.response?.data?.temp_pay_allowed);
        setIsLocked(data?.response?.data?.is_locked);
        localStorage.setItem("new_psw", data?.response?.data?.password_changed);
        setLoading(false);
      } else {
        localStorage.clear();
        navigate("/login");
      }
    };

    getData();
    getConnectionInfo();
    getMessages();

    return () => {
      controller.abort();
      setUpdate(false);
    };
  }, [update, id, navigate, token]);

  const getMessages = async () => {
    setMessLoading(true);
    try {
      const body = new FormData();
      body.append("token", token);

      const response = await fetch(getMsg, {
        body: body,
        method: "POST",
      });
      const data = await response.json();
      if (data.status) {
        data.messages && setMessages(data?.messages);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <HomePageContext.Provider
      value={{
        info,
        firstName,
        middleName,
        tempPay,
        setUpdate,
        activeTempPay,
        isLocked,
        blockUser,
        loading,
        fullName,
        setIsLocked,
        connInfo,
        messages,
        getMessages,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
};

export default HomePageContext;
