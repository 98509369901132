import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import SideBar from "../../components/sidebar/SideBar";
import MainInfo from "../../components/main/MainInfo";
import RightSide from "../../components/rightInfo/RightSide";
import "../../fonts/gilroy.css";
import "./home.scss";
import { HomeProvider } from "../../components/HomePageContext";
import Loading from "../../components/Loading/Loading";

export default function HomePage() {
  useEffect(() => {
    document.title = "Личный кабинет";
  }, []);
  return (
    <div className="home">
      <HomeProvider>
        <Loading />
        <Header />
        <div className="homecontainer">
          <SideBar />
          <MainInfo />
          <RightSide />
        </div>
      </HomeProvider>
    </div>
  );
}
