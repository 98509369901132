import React from "react";
import Auth from "./pages/authorization/Auth";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ConfirmPage from "./pages/confirmPage/ConfirmPage";
import RequiredAuth from "./components/RequiredAuth";
import RequiredNewPsw from "./components/RequiredNewPsw";
import HomePage from "./pages/home/HomePage";
import ForgetPsw from "./pages/forgetPswPage/ForgetPsw";
import { AuthProvider } from "./components/AppContext";
import PaymentsHistory from "./pages/PaymentsHistory/PaymentsHistory";
import CreatePassword from "./pages/createPassword/CreatePassword";
import Payment from "./pages/payforward/Payment";
import Feedback from "./pages/feedback/Feedback";
import "./breakpoints.scss";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route path="/forgot_password" element={<ForgetPsw />} />
            <Route path="/confirm" element={<ConfirmPage />} />
            <Route path="/create_password" element={<CreatePassword />} />
            <Route path="/payment/:id" element={<Payment />} />

            {/*  //!protected paths */}
            <Route element={<RequiredAuth />}>
              <Route element={<RequiredNewPsw />}>
                <Route index element={<HomePage />} />
                <Route path="/payments_history" element={<PaymentsHistory />} />
                {/* <Route path="/feedback" element={<Feedback />} /> */}
              </Route>
            </Route>
            {/*  //!protected paths */}
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
