import "./payment.scss";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const Payment = () => {
  const inputRef = useRef(null);
  // const savedId = localStorage.getItem("id");
  const [sum, setSum] = useState("");

  const [disabled, setDisabled] = useState(false);
  const payUrl = "https://api.cyxym.net/pay/v1?init";
  const params = useParams();

  useEffect(() => {
    document.title = "Оплата";
    inputRef.current.focus();
  }, []);

  const handleSubmit = async () => {
    setDisabled(true);
    const body = new FormData();

    try {
      body.append("id", params.id);
      body.append("amount", sum);

      const response = await fetch(payUrl, {
        method: "POST",
        body: body,
      });
      const data = await response.json();

      if (data.response?.confirmation?.confirmation_url)
        window.location.href = data.response?.confirmation.confirmation_url;
      setDisabled(false);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div className="payment">
      <div className="container">
        <span>укажите сумму</span>

        <input
          ref={inputRef}
          type="text"
          placeholder="сумма"
          value={sum}
          onChange={(e) => setSum(e.target.value)}
        />
        <Button
          className="button"
          onClick={handleSubmit}
          style={disabled || sum === "" ? { opacity: "0.5" } : { opacity: "1" }}
          disabled={disabled || sum === ""}
        >
          перейти к оплате
        </Button>
      </div>
    </div>
  );
};

export default Payment;
