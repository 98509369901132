import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./sidebar.scss";
import CurrencyRubleOutlinedIcon from "@mui/icons-material/CurrencyRubleOutlined";
import HomePageContext from "../HomePageContext";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function SideBar() {
  const navigate = useNavigate();
  const { info, tempPay, setUpdate, activeTempPay, isLocked, blockUser } =
    useContext(HomePageContext);
  const [open, setOpen] = useState(false);
  const [openTempPay, setTempPay] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenTemp = () => {
    setTempPay(true);
  };

  const handleCloseTemp = () => {
    setTempPay(false);
  };

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  return (
    <div className="sidebar">
      <span>Мой баланс</span>
      <div className="top">
        <div className="subIdInfo">
          <div className="ruble">
            <div className="icon">
              <CurrencyRubleOutlinedIcon
                style={{ color: "#29a9e0", fontWeight: 600 }}
                fontSize="large"
              />
            </div>
            <div className="textInfo">
              <p className="text">Лицевой счет №</p>
              <span className="number">{info.id}</span>
              <span style={{ display: "block" }}>{info.contract}</span>
            </div>
          </div>
        </div>
        <div className="balanceInfo">
          <div className="text">текущий баланс</div>
          <div className="balanceNumb">{info.balance} ₽</div>

          <div className="buttons">
            <Button className="button" onClick={() => setUpdate(true)}>
              Обновить
            </Button>
            <Button
              className="button"
              onClick={() => navigate("/payments_history")}
            >
              История платежей
            </Button>
          </div>
        </div>
      </div>

      <div className="bottom">
        <Button
          className="button"
          onClick={() => window.open(`https://e-pay.cyxym.net?id=${info.id}`)}
        >
          Пополнить баланс
        </Button>
        {tempPay && (
          <Button className="button" onClick={handleClickOpenTemp}>
            Доверительный платеж
          </Button>
        )}

        <Dialog
          open={openTempPay}
          onClose={handleCloseTemp}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {
              'Вы уверены, что хотите активировать услугу - "Доверительный платеж"?'
            }
          </DialogTitle>

          <DialogActions>
            <Button autoFocus onClick={handleCloseTemp}>
              Нет
            </Button>
            <Button
              onClick={() => {
                activeTempPay();
                handleCloseTemp();
                handleSnackOpen();
                setUpdate(true);
              }}
              autoFocus
            >
              Да
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Доверительный платеж активирован
          </Alert>
        </Snackbar>

        <Button className="button" onClick={handleClickOpen}>
          {isLocked ? "Разблокировать" : "Временная блокировка"}
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {isLocked
              ? "Вы уверены, что хотите разблокировать лицевой счет?"
              : "Вы уверены, что хотите временно заблокировать лицевой счет?"}
          </DialogTitle>

          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Нет
            </Button>
            <Button
              onClick={() => {
                blockUser();
                handleClose();
                setUpdate(true);
              }}
              autoFocus
            >
              Да
            </Button>
          </DialogActions>
        </Dialog>

        <Button className="button" onClick={() => navigate("/create_password")}>
          Сменить пароль
        </Button>

        {/* <Button className="button" onClick={() => navigate("/feedback")}>
          Чат с поддержкой
        </Button> */}
      </div>
    </div>
  );
}

export default SideBar;
