import React from "react";
import "./skeleton.scss";
function Skeleton({ type }) {
  const PaymentSkeleton = () => (
    <div className="itemSk">
      <div className="iconSk"></div>
      <div className="payTextSk">
        <div className="mainTextSk"></div>
        <div className="textTimeSk"></div>
      </div>

      <div className="amountSk"></div>
    </div>
  );

  if (type === "payment")
    return Array.from(Array(6), (_, i) => <PaymentSkeleton key={i} />);
}

export default Skeleton;
