import React, { useContext } from "react";
import "./header.scss";
import { useNavigate } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import logo from "../../assets/лого.svg";
import HomePageContext from "../HomePageContext";
import PersonIcon from "@mui/icons-material/Person";

function Header() {
  const { info, firstName, middleName, fullName } = useContext(HomePageContext);
  const full = middleName
    ? info.lastname + " " + firstName + "." + middleName + "."
    : fullName;
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="header">
      <div className="logo" onClick={() => navigate("/")}>
        <img src={logo} alt="systema-logo" />
      </div>

      <div className="buttonCont">
        <div className="button" style={{ backgroundColor: "transparent" }}>
          <div className="name">
            <div className="personIcon">
              <PersonIcon />
              <div className="fullName">
                {info.lastname || info.firstname ? full : fullName}
              </div>
            </div>
          </div>
        </div>
        <div className="logoutIcon" onClick={handleLogout}>
          <LogoutOutlinedIcon />
        </div>
      </div>
    </div>
  );
}

export default Header;
