import React from "react";
import "./rightSide.scss";
import poster1 from "../../assets/posters/poster1.png";
import poster2 from "../../assets/posters/poster2.png";
function RightSide() {
  return (
    <div className="rightSide">
      <span>Специальные предложения</span>
      <div className="posters">
        <div className="poster">
          <img src={poster1} alt="poster-img" />
        </div>
        <div className="poster">
          <img src={poster2} alt="poster-img" />
        </div>
      </div>
    </div>
  );
}

export default RightSide;
