import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import Header from "../../components/header/Header";
import { HomeProvider } from "../../components/HomePageContext";
import SideBar from "../../components/sidebar/SideBar";
import CurrencyRubleOutlinedIcon from "@mui/icons-material/CurrencyRubleOutlined";
import Skeleton from "../../components/sleleton/Skeleton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./paymentshistory.scss";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function PaymentsHistory() {
  const d = new Date();

  const currentDate =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    (d.getDate() + 1).toString().padStart(2, "0");

  const firstDay = d.getFullYear() + "-" + (d.getMonth() + 1) + "-01";

  const navigate = useNavigate();
  const id = localStorage.getItem("id") && localStorage.getItem("id");
  const token = localStorage.getItem("token") && localStorage.getItem("token");
  const [checkedWriteOff, setCheckedWriteOff] = useState(1);
  const [checkedOverDraft, setCheckedOverDraft] = useState(1);
  const [checkedPayment, setCheckedPayment] = useState(1);
  const [dateFrom, setDateFrom] = useState(firstDay);
  const [dateTo, setDateTo] = useState(currentDate);
  const [dataPayments, setDataPayments] = useState([]);
  const [isLoding, setIsLoading] = useState(false);

  const filterPay = "https://api.cyxym.net/app/v1?pay.list";

  const handleConfirm = async () => {
    const body = new FormData();
    setIsLoading(true);
    body.append("id", id);
    body.append("token", token);
    body.append("from", dateFrom);
    body.append("to", dateTo);
    body.append("payment", checkedPayment);
    body.append("writeoff", checkedWriteOff);
    body.append("overdraft", checkedOverDraft);
    try {
      const response = await fetch(filterPay, {
        body: body,
        method: "POST",
      });
      const data = await response.json();
      setDataPayments(data.response?.data);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (err) {}
  };

  return (
    <div className="history">
      <HomeProvider>
        <Loading />
        <Header />
        <div className="paymentContainer">
          <SideBar />
          <div className="mainContainer">
            <div className="back">
              <ArrowBackIcon
                style={{ color: "#29a9e0" }}
                onClick={() => navigate("/")}
              />
              <span>История платежей и списаний</span>
            </div>

            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FilterListIcon style={{ marginRight: "10px" }} />
                <Typography>Фильтр</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="filter accord">
                  <TextField
                    onChange={(e) => setDateFrom(e.target.value)}
                    label="С"
                    type="date"
                    sx={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dateFrom}
                    // defaultValue={firstDay}
                    className="date"
                  />

                  <TextField
                    onChange={(e) => setDateTo(e.target.value)}
                    id="date"
                    label="По"
                    type="date"
                    value={dateTo}
                    sx={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormControlLabel
                    onChange={(e) =>
                      setCheckedPayment(e.target.checked ? 1 : 0)
                    }
                    checked={checkedPayment ? true : false}
                    control={<Checkbox />}
                    label="платежи"
                  />

                  <FormControlLabel
                    onChange={(e) =>
                      setCheckedWriteOff(e.target.checked ? 1 : 0)
                    }
                    checked={checkedWriteOff ? true : false}
                    control={<Checkbox />}
                    label="списания"
                  />

                  <FormControlLabel
                    onChange={(e) =>
                      setCheckedOverDraft(e.target.checked ? 1 : 0)
                    }
                    checked={checkedOverDraft ? true : false}
                    control={<Checkbox />}
                    label="кредитный лимит"
                  />

                  <Button className="button" onClick={handleConfirm}>
                    фильтровать
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="filter remove">
              <TextField
                onChange={(e) => setDateFrom(e.target.value)}
                id="date"
                label="C"
                type="date"
                value={dateFrom}
                sx={{ width: 150 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                onChange={(e) => setDateTo(e.target.value)}
                value={dateTo}
                id="date"
                label="По"
                type="date"
                sx={{ width: 150 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <FormControlLabel
                onChange={(e) => setCheckedPayment(e.target.checked ? 1 : 0)}
                checked={checkedPayment ? true : false}
                control={<Checkbox />}
                label="платежи"
              />

              <FormControlLabel
                onChange={(e) => setCheckedWriteOff(e.target.checked ? 1 : 0)}
                checked={checkedWriteOff ? true : false}
                control={<Checkbox />}
                label="списания"
              />

              <FormControlLabel
                onChange={(e) => setCheckedOverDraft(e.target.checked ? 1 : 0)}
                checked={checkedOverDraft ? true : false}
                control={<Checkbox />}
                label="кредитный лимит"
              />

              <Button className="button" onClick={handleConfirm}>
                фильтровать
              </Button>
            </div>

            <div className="payments">
              <div className="items">
                {isLoding && dataPayments ? (
                  <Skeleton type="payment" />
                ) : (
                  dataPayments?.map((data) => (
                    <div className="item" key={data.n_line_no}>
                      <div className="icon">
                        <CurrencyRubleOutlinedIcon
                          style={{ color: "#fff", fontWeight: 600 }}
                          fontSize="large"
                        />
                      </div>
                      <div className="payText">
                        <span className="mainText">{data.v_description}</span>
                        <span className="textTime">{data.dt_oper}</span>
                      </div>

                      <div className="amount">{data.v_sum} ₽</div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </HomeProvider>
    </div>
  );
}

export default PaymentsHistory;
