import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import Button from "@mui/material/Button";
import "./forgetpsw.scss";
import logo from "../../assets/myLogo.png";
function ForgetPsw() {
  const resetPsw = "https://api.cyxym.net/app/v1?auth.byPhone";
  const navigate = useNavigate();
  const [userSubsId, setUserSubsId] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const phoneRef = useRef();
  const userRef = useRef();
  const errRef = useRef();

  // * when page loads input get focused
  useEffect(() => {
    document.title = "Забыли пароль?";
    userRef.current.focus();
  }, []);

  // * removing error messages when inputs change
  useEffect(() => {
    setErrMsg("");
    setDisabled(false);
  }, [userNumber]);

  const submitHandler = useCallback(async () => {
    setDisabled(true);
    try {
      const body = new FormData();
      body.append("id", userSubsId);
      body.append("phone", userNumber);
      localStorage.setItem("id", userSubsId);

      // * fething data
      const response = await fetch(resetPsw, {
        body: body,
        method: "POST",
      });

      const data = await response.json();
      console.log(data);
      if (data.response.status) {
        setUserSubsId("");
        setUserNumber("");
        localStorage.setItem("token", data.response.token);

        navigate("/create_password");
      } else {
        throw new Error(setErrMsg(data.response.message));
      }
      setDisabled(false);
    } catch (error) {
      setDisabled(false);
      errRef.current.focus();
    }
  }, [navigate, userSubsId, userNumber]);

  useEffect(() => {
    window.onkeydown = (event) => {
      if (event.keyCode === 13 && phoneRef.current) {
        submitHandler();
      }
    };
  }, [submitHandler]);

  return (
    <div className="forgot">
      <div className="loginContainer">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <div className="logo">
          <img src={logo} alt="systema-logo" />
        </div>

        <div className="cabinetText">Забыли пароль</div>
        <div className="inputs">
          <div className="idIcon">
            <PersonIcon style={{ color: "#29a9e0" }} />
            <input
              type="text"
              placeholder="Номер договора"
              value={userSubsId}
              ref={userRef}
              required
              onChange={(e) => setUserSubsId(e.target.value)}
            />
          </div>
          <div className="idIcon">
            <SmartphoneIcon style={{ color: "#29a9e0" }} />
            <input
              type="text"
              value={userNumber}
              ref={phoneRef}
              placeholder="Номер телефона (без +)"
              onChange={(e) => setUserNumber(e.target.value)}
              required
            />
          </div>
        </div>

        <Button
          className="button"
          style={
            disabled ||
            userSubsId === "" ||
            userSubsId.length !== 7 ||
            userNumber === "" ||
            userNumber.length < 11
              ? { opacity: "0.5" }
              : { opacity: "1" }
          }
          onClick={submitHandler}
          disabled={
            disabled ||
            userSubsId === "" ||
            userSubsId.length !== 7 ||
            userNumber === "" ||
            userNumber.length < 11
          }
        >
          продолжить
        </Button>
      </div>
    </div>
  );
}

export default ForgetPsw;
