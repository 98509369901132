import React, { useContext, useState } from "react";
import "./main.scss";
// import internet from "../../assets/icons/internet.png";
// import tv from "../../assets/icons/tv.png";
// import ip from "../../assets/icons/ip.png";
// import cam from "../../assets/icons/cam.png";
// import domPhone from "../../assets/icons/domPhone.png";
import HomePageContext from "../HomePageContext";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NumbersIcon from "@mui/icons-material/Numbers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function MainInfo() {
  const { info, connInfo } = useContext(HomePageContext);
  let now = new Date();
  let my = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const [checked, setChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const months = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];

  const ont_states = {
    1: "Загиб волокна / Проблемы с волокном",
    2: "Обрыв волокна",
    3: "Потеря фреймов от ONU",
    8: "Порт административно погашен",
    13: "Нормальное отключение питания",
    "-1": "Не удалось получить состояние",
  };

  return (
    <div className="mainInfo">
      <span>Мои тарифы и услуги</span>
      <div className="mainContainer">
        <div className="top">
          <span className="connectedInfo">Информация:</span>
          <div className="infoCont">
            <div className="info">
              <span className="name">{info?.fullname}</span>
              <div className="details">
                <span>
                  <ReceiptIcon /> Договор: {info?.contract}
                </span>
                <span>
                  <NumbersIcon /> Лицевой счет: {info?.id}
                </span>
                <span>
                  <HomeIcon /> Адрес: {info?.address}
                </span>

                <span>
                  <PhoneIphoneIcon /> Мобильный телефон: {"+" + info?.phone}
                </span>

                {info.last_pay !== null ? (
                  <span>
                    <CalendarMonthIcon /> Дата последнего платежа:{" "}
                    {info.last_pay}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="connectionInfo">
              {connInfo.type === "eth" ? (
                <>
                  <div className="conn">
                    <span className="name">Тип подключения: </span>
                    <span>Ethernet</span>
                  </div>

                  <div className="connection">
                    <span>Статус коммутатора: </span>
                    <span>
                      {connInfo.switch_pinging ? "включен" : "выключен"}
                    </span>
                  </div>
                  <div className="connection">
                    <span>Статус порта: </span>
                    <span>
                      {connInfo.interface_admin_status === "active"
                        ? "включен"
                        : "выключен"}
                    </span>
                  </div>

                  <div className="connection">
                    <span>Время работы: </span>
                    <span>{connInfo.interface_uptime}</span>
                  </div>

                  <div className="connection">
                    <span>Входящий трафик: </span>
                    <span>{connInfo.interface_in_octets}</span>
                  </div>

                  <div className="connection">
                    <span>Исходящий трафик: </span>
                    <span>{connInfo.interface_out_octets}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="conn">
                    <span className="name">Тип подключения: </span>
                    <span>GPON</span>
                  </div>

                  <div className="connection">
                    <span>Статус устройства: </span>
                    <span>
                      {connInfo.ont_status === "online"
                        ? "включен"
                        : "выключен"}
                    </span>
                  </div>
                  <div className="connection">
                    <span>Время работы устройства: </span>
                    <span>{connInfo.uptime}</span>
                  </div>
                  <div className="connection">
                    <span>Последняя причина отключения устройства: </span>
                    <span>{ont_states[connInfo.ont_state]}</span>
                  </div>
                </>
              )}
            </div>
            <div className="payInfo">
              <div className="tariff">
                <span>Тариф:</span>
                <span> {info.tariff}</span>
              </div>
              {info.price !== 0 ? (
                <span className="amount">Абонплата: {info.price} ₽</span>
              ) : null}
              <div className="payText">
                <div>
                  <span>Состояние:</span>
                  <span> {info.state}</span>
                </div>
                {info.price !== 0 ? (
                  <div>
                    <span>Рекомендуемый платеж:</span>
                    <span> {info.rec_pay} ₽</span>
                  </div>
                ) : null}

                {info.tariff !== "Сотрудник" ? (
                  <>
                    <span>Списание</span>
                    <span>
                      1 {months[my.getMonth()]} {my.getFullYear()}
                    </span>
                  </>
                ) : null}
              </div>
            </div>

            <div className="autoPay">
              <div className="sett">
                <span>Настройки</span>
              </div>

              <div className="toggleText">
                <span>Автоплатежи</span>
                <Switch
                  checked={checked}
                  onChange={handleClickOpen}
                  size="small"
                />
              </div>
            </div>
          </div>

          {/* <span className="connected">Подключенные:</span> */}
        </div>
        {/* <div className="bottom">
          <span className="services">Все услуги:</span>
          <div className="blocks">
            <div className="internetBlock">
              <img src={internet} alt="internet-icon" />
              <span>Интернет</span>
            </div>
            <div className="tvBlock">
              <img src={tv} alt="tv-icon" />
              <span>Телевидение</span>
            </div>
            <div className="staticIpBlock">
              <img src={ip} alt="ip-icon" />
              <span>Статический IP</span>
            </div>
            <div className="cctvBlock">
              <img src={cam} alt="cam-icon" />
              <span>Видеонаблюдение</span>
            </div>
            <div className="domPhoneBlock">
              <img src={domPhone} alt="domPhone-icon" />
              <span>Домофония</span>
            </div>
          </div>
        </div> */}
      </div>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Автоплатежи временно недоступны
        </DialogTitle>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Ок
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MainInfo;
