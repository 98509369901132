import React, { useContext } from "react";
import HomePageContext from "../HomePageContext";
import "./loading.scss";

function Loading() {
  const { loading } = useContext(HomePageContext);
  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
          <div>Загрузка...</div>
        </div>
      )}
    </>
  );
}

export default Loading;
