import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import "./auth.scss";
import logo from "../../assets/myLogo.png";
import AppContext from "../../components/AppContext";
import PersonIcon from "@mui/icons-material/Person";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";

export default function Auth() {
  const navigate = useNavigate();
  const { setAuth } = useContext(AppContext);
  const [userSubsId, setUserSubsId] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [focusedId, setFocusedId] = useState(false);
  const [errId, setErrId] = useState("");
  const [errPsw, setErrPsw] = useState("");
  const [focusedPsw, setFocusedPsw] = useState(false);
  const userRef = useRef();
  const phoneRef = useRef();
  const errRef = useRef();

  const authUrl = "https://api.cyxym.net/app/v1?auth";

  const handleFocusedId = (e) => {
    setFocusedId(true);
    if (userSubsId === "") setErrId("Не указан номер договора");
  };

  const handleFocusedPsw = (e) => {
    setFocusedPsw(true);
    if (userNumber === "") setErrPsw("Не указан пароль");
  };

  const togglePsw = () => {
    setPasswordShown(!passwordShown);
  };
  // * when page loads input get focused
  useEffect(() => {
    document.title = "Вход в личный кабинет";
    userRef.current.focus();
  }, []);

  // * removing error messages when inputs change
  useEffect(() => {
    setErrMsg("");
    setDisabled(false);
  }, [userSubsId, userNumber]);

  const submitHandler = useCallback(async () => {
    setDisabled(true);
    try {
      const body = new FormData();
      body.append("id", userSubsId);
      body.append("password", userNumber);

      // * fething data
      const response = await fetch(authUrl, {
        body: body,
        method: "POST",
      });

      const data = await response.json();
      if (data.response.status) {
        setUserSubsId("");
        setUserNumber("");
        setAuth({ userSubsId, userNumber });
        if (data.response?.password_changed) {
          localStorage.setItem("token", data.response.token);
          localStorage.setItem("id", userSubsId);
          localStorage.setItem("new_psw", true);
          navigate("/");
        } else {
          localStorage.setItem("token", data.response.token);
          localStorage.setItem("id", userSubsId);
          navigate("/create_password");
        }
        setDisabled(false);
      } else {
        throw new Error(setErrMsg(data.response.message));
      }
    } catch (error) {
      setDisabled(false);
      errRef.current.focus();
    }
  }, [userNumber, userSubsId, setAuth, navigate]);

  useEffect(() => {
    window.onkeydown = (event) => {
      if (event.keyCode === 13 && (userRef.current || phoneRef.current)) {
        submitHandler();
      }
    };
  }, [submitHandler]);

  return (
    <div className="auth">
      <div className="loginContainer">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <div className="logo">
          <img src={logo} alt="systema-logo" />
        </div>

        <div className="cabinetText">Личный кабинет</div>
        <div className="inputs">
          <div className="idIcon">
            <PersonIcon style={{ color: "#29a9e0" }} />
            <input
              type="text"
              placeholder="Номер договора"
              value={userSubsId}
              ref={userRef}
              required
              onChange={(e) => setUserSubsId(e.target.value)}
              onBlur={handleFocusedId}
              focused={focusedId.toString()}
              pattern="^[0-9]{7}$"
            />

            <span>{errId}</span>
          </div>

          <div className="idIcon">
            <SmartphoneIcon style={{ color: "#29a9e0" }} />
            <input
              type={passwordShown ? "text" : "password"}
              value={userNumber}
              ref={phoneRef}
              placeholder="Пароль"
              onChange={(e) => setUserNumber(e.target.value)}
              required
              onBlur={handleFocusedPsw}
              focused={focusedPsw.toString()}
              pattern="^.{6,}$"
            />
            <span>{errPsw}</span>
            {passwordShown ? (
              <VisibilityOffIcon
                style={{ color: "#29a9e0", cursor: "pointer" }}
                onClick={togglePsw}
              />
            ) : (
              <VisibilityIcon
                style={{ color: "#29a9e0", cursor: "pointer" }}
                onClick={togglePsw}
              />
            )}
          </div>

          <div
            className="forgetPsw"
            onClick={() => navigate("/forgot_password")}
          >
            Забыли пароль?
          </div>
        </div>
        <Button
          className="button"
          style={
            disabled ||
            userSubsId === "" ||
            userSubsId.length !== 7 ||
            userNumber === "" ||
            userNumber.length < 6
              ? { opacity: "0.5" }
              : { opacity: "1" }
          }
          onClick={submitHandler}
          disabled={
            disabled ||
            userSubsId === "" ||
            userSubsId.length !== 7 ||
            userNumber === "" ||
            userNumber.length < 6
          }
        >
          войти
        </Button>
      </div>
    </div>
  );
}
