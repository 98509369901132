import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequiredNewPsw = () => {
  const location = useLocation();

  return localStorage.getItem("new_psw") === "true" ? (
    <Outlet />
  ) : (
    <Navigate to="/create_password" state={{ from: location }} replace />
  );
};

export default RequiredNewPsw;
